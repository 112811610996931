import React, { useEffect, useReducer, useCallback, useMemo } from "react";
import { dezThemeSet } from "./ThemeDemo";
import { Background, State, ThemeContext, ThemeContextProviderProps, ThemeKeys, ThemeSettings } from "../interfaces/ThemeInterface";

// Reducer to manage state
const reducer = (previousState: State, updatedState: Partial<State>): State => ({
  ...previousState,
  ...updatedState,
});

// Initial state for the context
const initialState: State = {
  primaryColor: "color-skin-1",
  background: { value: "default", label: "default" },
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
};

const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { primaryColor, background } = state;

  const body = useMemo(() => document.querySelector("body"), []);

  // Options for background
  const backgroundOption = useMemo(() => [
    { value: "default", label: "default" },
    { value: "data-typography-1", label: "data-typography-1" },
  ], []);

  // Change primary color function
  const changePrimaryColor = useCallback((name: string) => {
    dispatch({ primaryColor: name });
    if (body) {
      body.setAttribute("data-primary", name);
    }
  }, [body]);

  // Change background function
  const changeBackground = useCallback((name: Background) => {
    if (body) {
      body.setAttribute("data-theme-version", name.value);
    }
    dispatch({ background: name });
  }, [body]);

  // Set demo theme function
  const setDemoTheme = useCallback((theme: ThemeKeys) => {
    const themeSettings = dezThemeSet[theme] as ThemeSettings;

    if (body && themeSettings.typography) {
      body.setAttribute("data-typography", themeSettings.typography);
    }

    if (themeSettings.version) {
      const setAttr: Background = { value: themeSettings.version, label: themeSettings.version };
      changeBackground(setAttr);
    }

    if (themeSettings.primary) {
      changePrimaryColor(themeSettings.primary);
    }
  }, [body, changeBackground, changePrimaryColor]);

  // Effect for handling initial setup and window resize
  useEffect(() => {
    if (body) {
      body.setAttribute("data-theme-version", "default");
      body.setAttribute("data-primary", "color-skin-1");
    } else {
      console.warn("Body element not found.");
    }

    const resizeWindow = () => {
      // Example: Dispatch window size updates if necessary
      dispatch({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };

    // Initial call to set the state or handle size
    resizeWindow();

    // Add resize event listener
    window.addEventListener("resize", resizeWindow);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, [body]);

  // Memoized context value
  const contextValue = useMemo(() => ({
    body,
    backgroundOption,
    primaryColor,
    changePrimaryColor,
    changeBackground,
    background,
    setDemoTheme,
  }), [body, backgroundOption, primaryColor, changePrimaryColor, changeBackground, background, setDemoTheme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
