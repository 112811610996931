import React from 'react';
import { Accordion } from 'react-bootstrap';

import PageBanner from '../layouts/PageBanner';
import bgImage from './../assets/images/banner/bnr4.webp';
import project3 from './../assets/images/project/pic3.webp';

const accordBlog = [
    { title: "What is Daytocode's mission?", content: "Daytocode's mission is to build self-confidence in youth and women in underserved communities through education in computer programming and technology, fostering economic inclusion and digital literacy." },
    { title: "Where does Daytocode operate?", content: "Daytocode operates in the United States, as well as in Africa." },
    { title: "What types of programs does Daytocode offer?", content: "Daytocode offers robotics workshops in Montgomery County, MD, summer coding camps in Wilmington, NC, and international outreach programs focusing on technology education and girls' empowerment in Guinea and Côte d'Ivoire." },
    { title: "How can I get involved with Daytocode?", content: "You can get involved by volunteering as an instructor, mentor, or event coordinator. Organizations can partner with us to extend our reach, and individuals can donate to support our programs, equipment purchases, and scholarships." },
    { title: "What impact has Daytocode made?", content: "Since 2016, Daytocode has engaged over 500 youth across three continents, conducted more than 100 coding and robotics workshops, and impacted over 200 students in Guinea alone in the last year." },
];

const Faq = () => {
    // Explicitly typing the parameters
    function isScrolledIntoView(elem: string) {
        const spliBox = document.querySelectorAll<HTMLElement>(elem);
        spliBox.forEach((item: HTMLElement) => {
            const docViewTop = window.scrollY;
            const docViewBottom = docViewTop + window.innerHeight;
            const elemTop = item.getBoundingClientRect().top + docViewTop;
            const elemBottom = elemTop + item.offsetHeight;
            if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop)) {
                item.classList.add('split-active');
            }
        });
    }

    window.addEventListener("scroll", () => {
        isScrolledIntoView('.split-box');
    });

    return (
        <div className="page-content bg-white">
            <PageBanner maintitle="Home" pagetitle="FAQ" background={bgImage} />
            <section className="content-inner">
                <div className="container">
                    <div className="row align-items-center flex-column-reverse flex-lg-row">
                        <div className="col-lg-6 align-self-center">
                            <div className="section-head m-b30">
                                <h2 className="title">Frequently Asked Questions</h2>
                                <p>Find answers to common questions about Daytocode, our programs, and how you can get involved.</p>
                            </div>
                            <Accordion className="accordion dz-accordion accordion-sm" id="accordionFaq1">
                                {accordBlog.map((item, index) => (
                                    <Accordion.Item className="accordion-item" key={index} eventKey={index.toString()}>
                                        <Accordion.Header as="h2" id={`headingOne${index}`}>
                                            {item.title}
                                            <span className="toggle-close"></span>
                                        </Accordion.Header>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Accordion.Body>
                                                <p className="m-b0">{item.content}</p>
                                            </Accordion.Body>
                                        </Accordion.Collapse>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1 m-b30">
                            <div className="dz-media split-box rounded">
                                <div>
                                    <img src={project3} alt="Daytocode programs in action" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Faq;
