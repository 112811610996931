import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import bnr3 from './../assets/images/background/dtc_robotic_bg.webp';
import BgImage from './../assets/images/background/dtc_williston_intro_robotic.webp';
import pattern1 from './../assets/images/counter/pattren1.webp';
import pattern2 from './../assets/images/counter/pattren2.webp';
import pattern3 from './../assets/images/counter/pattren3.webp';
import pattern4 from './../assets/images/counter/pattren4.webp';
import pattern5 from './../assets/images/counter/pattren5.webp';
import pattern6 from './../assets/images/counter/pattren6.webp';

import OurMission from '../components/OurMission';
import CategoriesIcon from '../components/CategoriesIcon';

const BecomeVolunteer: React.FC = () => {
    const nav = useNavigate();

    const FormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        nav("/contact-us");
    };

    return (
        <div className="page-content bg-white">
            <div className="dz-bnr-inr style-1 text-center overlay-primary-dark" style={{ backgroundImage: `url(${bnr3})` }}>
                <div className="container">
                    <div className="dz-bnr-inr-entry">
                        <h1>Become A Volunteer</h1>
                        <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                                <li className="breadcrumb-item active">Become A Volunteer</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <section className="gradient-white" style={{ backgroundImage: `url(${BgImage})`, backgroundPosition: "center" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9 col-sm-12">
                            <div className="form-wrapper style-3">
                                <div className="contact-area">
                                    <h2 className="mb-4">Join Our Team as a Volunteer</h2>
                                    <p className="mb-4">Help us empower the next generation through technology education. We&apos;re looking for instructors, mentors, and event coordinators to support our programs.</p>
                                    <form className="dz-form dzForm contact-bx" onSubmit={FormSubmit}>
                                        <div className="dzFormMsg"></div>
                                        <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                        <input type="hidden" className="form-control" name="reCaptchaEnable" value="0" />
                                        
                                        <div className="row sp15">
                                            <div className="col-md-12">
                                                <label className="form-label" htmlFor="dzName">Full Name</label>
                                                <div className="input-group">
                                                    <input id="dzName" name="dzName" required type="text" className="form-control" placeholder="Marchelo Queque" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="dzEmail">Email address</label>
                                                <div className="input-group">
                                                    <input id="dzEmail" name="dzEmail" required type="text" className="form-control" placeholder="marseloque@mail.com" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="dzPhoneNumber">Phone Number</label>
                                                <div className="input-group">
                                                    <input id="dzPhoneNumber" name="dzPhoneNumber" required type="text" className="form-control" placeholder="987 654 3210" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label" htmlFor="dzCompanyName">Company Name</label>
                                                <div className="input-group">
                                                    <input id="dzCompanyName" name="dzOther[company_Name]" required type="text" className="form-control" placeholder="Marchelo Studios" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label" htmlFor="dzVolunteerRole">Preferred Volunteer Role</label>
                                                <div className="input-group">
                                                    <select id="dzVolunteerRole" name="dzOther[volunteer_role]" required className="form-control">
                                                        <option value="">Select a role</option>
                                                        <option value="instructor">Instructor</option>
                                                        <option value="mentor">Mentor</option>
                                                        <option value="event_coordinator">Event Coordinator</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label" htmlFor="dzMessage">Why do you want to volunteer with Daytocode?</label>
                                                <div className="input-group">
                                                    <textarea id="dzMessage" name="dzMessage" rows={5} required className="form-control" placeholder="Tell us about your interest in our mission and how you&apos;d like to contribute."></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-12 m-b20">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Accept terms & conditions
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button name="submit" type="submit" value="Submit" className="btn btn-secondary effect">SEND</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>	
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content-inner-2">
                <div className="container">
                    <div className="row about-bx3 align-items-center">
                        <OurMission />
                    </div>
                </div>
            </section>
            <div className="counter-wrapper-1">
                <div className="container">
                    <div className="counter-inner bg-secondary">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 m-b30">
                                <div className="counter-style-1">
                                    <div className="text-secondary">
                                        <span className="counter">500+</span>
                                    </div>
                                    <span className="counter-text">Youth Engaged</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 m-b30">
                                <div className="counter-style-1">
                                    <div className="text-secondary">
                                        <span className="counter">100+</span>
                                    </div>
                                    <span className="counter-text">Workshops Conducted</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 m-b30">
                                <div className="counter-style-1">
                                    <div className="text-secondary">
                                        <span className="counter">4</span>
                                    </div>
                                    <span className="counter-text">Countries Impacted</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 m-b30">
                                <div className="counter-style-1">
                                    <div className="text-secondary">
                                        <span className="counter">200+</span>
                                    </div>
                                    <span className="counter-text">Students in Guinea</span>
                                </div>
                            </div>
                        </div>
                        <img src={pattern1} className="pattren1 move-2" alt="" />
                        <img src={pattern2} className="pattren2 move-2" alt="" />
                        <img src={pattern3} className="pattren3 move-2" alt="" />
                        <img src={pattern4} className="pattren4 move-2" alt="" />
                        <img src={pattern5} className="pattren5 move-2" alt="" />
                        <img src={pattern6} className="pattren6 move-2" alt="" />
                    </div>
                </div>
            </div>
            <section className="content-inner-2">
                <div className="container">
                    <div className="section-head text-center">
                        <h5 className="sub-title">OUR PROGRAMS</h5>
                        <h2 className="title">Explore Our Technology Education Programs</h2>
                        <p>Our programs are designed to foster digital literacy, critical thinking, and problem-solving skills among youth and women, equipping them for future opportunities in the tech-driven world.</p>
                    </div>
                    <div className="row">
                        <CategoriesIcon />                     
                    </div>
                </div>
            </section>
            <div className="call-action style-1 content-inner-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-8 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
                            <h2 className="title">Ready to make a difference?</h2>
                            <p className="m-b0">Join us in empowering the next generation through technology education.</p>
                        </div>
                        <div className="col-lg-4 col-md-4 text-right m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.4s">
                            <Link to={"/contact-us"} className="btn btn-primary btn-rounded">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BecomeVolunteer;
