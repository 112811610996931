import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";

import ScrollToTop from "../layouts/ScrollToTop";
import Header from "../layouts/Header";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Volunteer from "./Volunteer";
import BecomeVolunteer from "./BecomeVolunteer";
import Faq from "./Faq";
import Testimonials from "./Testimonials";
import Mission from "./Mission";
import Error from "./Error";
import FundraiserDetail from "./FundraiserDetail";
import Program from "./Program";
import ProjectCategories from "./ProjectCategories";
import ProjectStory from "./ProjectStory";
import Blog from "./Blog";
import BlogGrid from "./BlogGrid";
import BlogDetails from "./BlogDetails";
import ContactUs from "./ContactUs";
import BecomeFundraiser from "./BecomeFundraiser";

function Index() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* Routes that don't use MainLayout */}
        <Route path="/error-404" element={<Error />} />
        <Route path="/" element={<Home />} />
        {/* Main Layout for other routes */}
        <Route element={<MainLayout />}>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/volunteer" element={<Volunteer />} />
          <Route path="/become-a-volunteer" element={<BecomeVolunteer />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/become-a-fundraiser" element={<BecomeFundraiser />} />
          <Route path="/fundraising" element={<FundraiserDetail />} />
          <Route path="/workshops" element={<Program />} />
          <Route path="/past-workshops" element={<ProjectCategories />} />
          <Route path="/story" element={<ProjectStory />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-grid" element={<BlogGrid />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>

        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

function MainLayout() {
  return (
    <div className="page-wraper">
      <Header changeStyle={""} changeLogo={false} logo={""} />
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
}

export default Index;
