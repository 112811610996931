import { createContext, ReactNode } from "react";
import { dezThemeSet } from "../context/ThemeDemo";

// Define the types for the theme context
export interface Background {
    value: string;
    label: string;
  }
  export interface ThemeContextProviderProps {
    children: ReactNode;
  }
  
  export interface ThemeSettings {
    typography: string;
    version: string;
    primary: string;
    layout: string;
    headerBg: string;
    navheaderBg: string;
    sidebarBg: string;
    sidebarStyle: string;
    sidebarPosition: string;
    headerPosition: string;
    containerLayout: string;
    direction: string;
  }
  
  
  export interface State {
      primaryColor: string;
      background: Background;
      windowWidth: number;
      windowHeight: number;
    }
    export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);
    
    export interface ThemeContextType {
        body: HTMLElement | null;
        backgroundOption: Background[];
        primaryColor: string;
        changePrimaryColor: (name: string) => void;
        changeBackground: (name: Background) => void;
        background: Background;
        setDemoTheme: (theme: ThemeKeys) => void;
    }
    export type ThemeKeys = keyof typeof dezThemeSet;
  
  