import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const FourthStep = () => {
    const [involvement, setInvolvement] = useState('Volunteer');
    const [location, setLocation] = useState('Maryland');

    return (
        <div id="wizard_info" className="tab-pane" role="tabpanel">
            <h4 className="title">Additional Information</h4>
            <div className="row">
                {/* ... existing name and phone inputs ... */}
                <div className="col-md-12">
                    <label className="form-label" htmlFor="involvementDropdown">How would you like to be involved?</label>
                    <Dropdown className="select-drop">
                        <Dropdown.Toggle as="div" className="i-false select-drop-btn" id="involvementDropdown">
                            <span>{involvement}</span>
                            <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setInvolvement('Volunteer')}>Volunteer</Dropdown.Item>
                            <Dropdown.Item onClick={() => setInvolvement('Donor')}>Donor</Dropdown.Item>
                            <Dropdown.Item onClick={() => setInvolvement('Partner')}>Partner</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="col-md-12 mt-3">
                    <label className="form-label" htmlFor="locationDropdown">Preferred Location</label>
                    <Dropdown className="select-drop">
                        <Dropdown.Toggle as="div" className="i-false select-drop-btn" id="locationDropdown">
                            <span>{location}</span>
                            <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setLocation('Maryland')}>Maryland</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLocation('North Carolina')}>North Carolina</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLocation('Guinea')}>Guinea</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLocation('Côte d\'Ivoire')}>Côte d&apos;Ivoire</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="col-md-12 mt-3">
                    <label className="form-label" htmlFor="message">Additional Comments</label>
                    <textarea id="message" name="message" className="form-control" rows={4} placeholder="Any specific interests or skills you'd like to share?"></textarea>
                </div>
            </div>
        </div>
    );
};

export default FourthStep;
