import React, { useState } from "react"; // Import useState
import { Link } from "react-router-dom";
import BlogDetailsLeftBar from "../components/BlogDetailsLeftBar";

import { cardDetails } from "./Blog";

const BlogDetails: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update search term
  };

  return (
    <>
      <div className="page-content bg-white">
        <section className="content-inner-2">
          <div className="container">
            <div className="row ">
              <div className="col-xl-8 col-lg-8 m-b30">
                <BlogDetailsLeftBar />
              </div>
              <div className="col-xl-4 col-lg-4">
                <aside className="side-bar sticky-top">
                  <div className="widget style-1">
                    <div className="widget-title">
                      <h5 className="title">Search</h5>
                    </div>
                    <div className="search-bx">
                      <form role="search" onSubmit={(e) => e.preventDefault()}>
                        <div className="input-group">
                          <input
                            name="text"
                            className="form-control"
                            placeholder="Search Here..."
                            type="text"
                            value={searchTerm} // Bind input value to state
                            onChange={handleSearchChange} // Handle input change
                          />
                          <span className="input-group-btn">
                            <button
                              type="submit"
                              className="btn btn-primary sharp radius-no"
                            >
                              <i className="la la-search scale3"></i>
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="widget style-1 recent-posts-entry">
                    <div className="widget-title">
                      <h5 className="title">Recent Post</h5>
                    </div>
                    <div className="widget-post-bx">
                      {cardDetails.slice(0, 4).map((item, ind) => (
                        <div className="widget-post clearfix" key={ind}>
                          <div className="dz-info">
                            <h6 className="title">
                              <Link to={"/blog-details"}>{item.title}</Link>
                            </h6>
                            <div className="dz-meta">
                              <ul>
                                <li className="post-date">
                                  <i className="fa-solid fa-calendar-days"></i>{" "}
                                  July 7-14, 2024
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogDetails;
