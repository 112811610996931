import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PageBanner from "../layouts/PageBanner";

//images
import bg from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_31.webp";

import pic4 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_15.webp";
import pic3 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_16.webp";
import pic2 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_18.webp";
import pic5 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_19.webp";
import pic6 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_20.webp";
import pic7 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_21.webp";
import pic8 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_22.webp";
import pic9 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_23.webp";
import pic1 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_24.webp";

import avat1 from "../assets/images/avatar/avatar1.webp";
import avat2 from "../assets/images/avatar/avatar2.webp";
import avat3 from "../assets/images/avatar/avatar3.webp";
import avat4 from "../assets/images/avatar/avatar4.webp";
import avat5 from "../assets/images/avatar/avatar5.webp";
import avat6 from "../assets/images/avatar/avatar6.webp";
import avat7 from "../assets/images/avatar/avatar7.webp";
import avat8 from "../assets/images/avatar/avatar8.webp";
import avat9 from "../assets/images/avatar/avatar9.webp";
export interface CardDetail {
    title: string;
    summary: string;
    image: string;
    image2: string;
    tag: string;
    author: string;
    date: string; // Ensure date is included in the type
}
export const cardDetails = [
  {
    title: "Participation to the Arise 2K24 Conference",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic4,
    image2: avat1,
    tag: "CODING",
    author: "Daytocode Team",
    date: "2024-07-10",
  },
  {
    title: "Daytocode robotics workshop April 2024",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic3,
    image2: avat2,
    tag: "ROBOTICS",
    author: "Daytocode Team",
    date: "2024-04-25",
  },
  {
    title: "Robotics workshop, Harvest Tech Fair 1st Edition",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic2,
    image2: avat3,
    tag: "ROBOTICS",
    author: "Daytocode Team",
    date: "2023-09-30",
  },
  {
    title: "Daytocode Guinea with Koumandjan High School",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic5,
    image2: avat4,
    tag: "ROBOTICS",
    author: "Daytocode Team",
    date: "2023-04-13",
  },
  {
    title: "Daytocode Summer Coding in Wilmington, NC with DAI and NHCS",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic6,
    image2: avat5,
    tag: "EDUCATION",
    author: "Daytocode Team",
    date: "2024-06-30",
  },
  {
    title: "Williston Middle School, Wilmington, NC",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic7,
    image2: avat6,
    tag: "ROBOTICS",
    author: "Daytocode Team",
    date: "2022-06-13",
  },
  {
    title: "Coding and Robotics at Trask Middle",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic8,
    image2: avat7,
    tag: "ROBOTICS",
    author: "Daytocode Team",
    date: "2022-06-14",
  },
  {
    title: "Coding and Robotics at Murray Middle School",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic9,
    image2: avat8,
    tag: "EDUCATION",
    author: "Daytocode Teamn",
    date: "2022-06-19",
  },
  {
    title: "Daytocode Summer Coding Camp at SNIPES Elementary School",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et aliqua.",
    image: pic1,
    image2: avat9,
    tag: "ROBOTICS",
    author: "Daytocode Team",
    date: "2021-06-11",
  },
];


const Blog = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [blogDropBtn1, setBlogDropBtn1] = useState<string>("All Categories"); // Ensure initial state is set
  const [blogDropBtn2, setBlogDropBtn2] = useState("Filter Date");
  const [blogDropBtn3, setBlogDropBtn3] = useState("Latest"); // Ensure initial state is set
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const uniqueTags = Array.from(new Set(cardDetails.map(item => item.tag))); // Get unique tags
  const uniqueDates = Array.from(new Set(cardDetails.map(item => item.date))); // Get unique dates

  // Filter and sort cardDetails based on search term and dropdown selections
  const filteredItems = cardDetails.filter(item =>
    (blogDropBtn1 === "All Categories" || item.tag === blogDropBtn1) &&
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort filtered items
  const sortedItems = filteredItems.sort((a, b) => {
    if (blogDropBtn3 === "Latest") {
      return new Date(b.date).getTime() - new Date(a.date).getTime(); // Descending order
    } else if (blogDropBtn3 === "Oldest") {
      return new Date(a.date).getTime() - new Date(b.date).getTime(); // Ascending order
    }
    return 0; // Default case
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Blog " background={bg} />
        <section className="content-inner-1">
          <div className="container">
            <div className="row m-b30">
              <div className="col-lg-5 search-bx style-2">
                <div className="input-group m-b0 m-md-b10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Articles"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="input-group-prepend">
                    <button className="btn">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row g-3 justify-content-end">
                  <div className="col-sm-4">
                    <Dropdown className="select-drop">
                      <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                        <span>{blogDropBtn1}</span>
                        <i className="fa-regular fa-angle-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setBlogDropBtn1("All Categories")}>
                          All Categories
                        </Dropdown.Item>
                        {uniqueTags.map(tag => (
                          <Dropdown.Item key={tag} onClick={() => setBlogDropBtn1(tag)}>
                            {tag}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-sm-4">
                    <Dropdown className="select-drop">
                      <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                        <span>{blogDropBtn2}</span>
                        <i className="fa-regular fa-angle-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setBlogDropBtn2("Filter Date")}>
                          Filter Date
                        </Dropdown.Item>
                        {uniqueDates.map(date => (
                          <Dropdown.Item key={date} onClick={() => setBlogDropBtn2(date)}>
                            {date}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-sm-4">
                    <Dropdown className="select-drop">
                      <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                        <span>{blogDropBtn3}</span>
                        <i className="fa-regular fa-angle-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setBlogDropBtn3("Latest")}>
                          Latest
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setBlogDropBtn3("Oldest")}>
                          Oldest
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {currentItems.map((item, ind) => ( // Ensure currentItems is based on sortedItems
                <div className="col-xl-4 col-md-6 m-b30" key={ind}>
                  <div className="dz-card style-1">
                    <div className="dz-media">
                      <Link to={"/blog-details"}>
                        <img src={item.image} alt={item.title} />
                      </Link>
                      <ul className="dz-badge-list">
                        <li>
                          <Link to={"#"} className="dz-badge">
                            {item.tag} {/* Use tag as the link text */}
                          </Link>
                        </li>
                      </ul>
                      <Link to={"/blog-details"} className="btn btn-secondary">
                        Read More
                      </Link>
                    </div>
                    <div className="dz-info">
                      <h5 className="dz-title">
                        <Link to={"/blog-details"}>{item.title}</Link>
                      </h5>
                      <p>{item.summary}</p>
                      <div className="author-wrappper">
                        <div className="author-media">
                          <img src={item.image2} alt={item.author} />
                        </div>
                        <div className="author-content">
                          <div className="author-head">
                            <h6 className="author-name">{item.author}</h6>
                          </div>
                          <ul className="author-meta">
                            <li className="date">{item.date}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-12 m-sm-t0 m-t30">
                <nav className="pagination-bx">
                  <div className="page-item">
                    <Link
                      to={"#"}
                      className="page-link prev"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </Link>
                  </div>
                  <ul className="pagination">
                    {Array.from(
                      { length: Math.ceil(cardDetails.length / itemsPerPage) },
                      (_, index) => (
                        <li className="page-item" key={index}>
                          <Link
                            to={"#"}
                            className={`page-link ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="page-item">
                    <Link
                      to={"#"}
                      className="page-link next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blog;
