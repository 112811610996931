import React from 'react';

const SecondStep = () => {
    return (
        <div id="wizard_Service" className="tab-pane" role="tabpanel">
            <h4 className="title">Your Information</h4>
            <div className="row sp15">
                <div className="col-md-6">
                    <label className="form-label" htmlFor="firstName">First Name</label>
                    <div className="input-group">
                        <input id="firstName" name="firstName" required type="text" className="form-control" placeholder="First Name" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="form-label" htmlFor="lastName">Last Name</label>
                    <div className="input-group">
                        <input id="lastName" name="lastName" required type="text" className="form-control" placeholder="Last Name" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="form-label" htmlFor="dzPhoneNumber">Phone Number</label>
                    <div className="input-group">
                        <input id="dzPhoneNumber" name="dzPhoneNumber" required type="text" className="form-control" placeholder="832141251" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="form-label" htmlFor="dzEmail">Email address</label>
                    <div className="input-group">
                        <input id="dzEmail" name="dzEmail" required type="text" className="form-control" placeholder="marseloque@mail.com" />
                    </div>
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <label className="form-label" htmlFor="involvement">How would you like to get involved?</label>
                <select id="involvement" name="involvement" className="form-select">
                    <option value="">Select an option</option>
                    <option value="volunteer">Volunteer as an instructor or mentor</option>
                    <option value="partner">Partner with Daytocode</option>
                    <option value="donate">Make a donation</option>
                    <option value="other">Other</option>
                </select>
            </div>
        </div>
    );
};

export default SecondStep;
