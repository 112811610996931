import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

// Images

import slide2 from "../assets/images/project/wilmington_nc/williston_intro_robotic_1.webp";
import bg10 from "../assets/images/background/dtc_robotic_bg.webp";

// Layouts
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { IMAGES } from "../constant/theme"; // Ensure IMAGES is imported correctly

// Components
import Mainslider from "../components/Home/Mainslider";
import PartnershipSlider from "../components/Home/PartnershipSlider";
import FooterTop from "../components/Home/FooterTop";
import { ThemeContext } from "../interfaces/ThemeInterface";


const Home = () => {
  const themeContext = useContext(ThemeContext);
  const { changeBackground, changePrimaryColor } = themeContext || {};

  useEffect(() => {
    if (changeBackground && changePrimaryColor) {
      changeBackground({
        value: "data-typography-1",
        label: "data-typography-1",
      });
      changePrimaryColor("color-skin-2");
    }
  }, [changeBackground, changePrimaryColor]);

  const [readModal, setReadModal] = useState(false);

  return (
    <>
      <Header
        logo={IMAGES.logo2 as unknown as string}
        changeStyle={""}
        changeLogo={false}
      />
      <div className="page-content bg-white">
        <div className="main-bnr-one">
          <Mainslider />
        </div>

        {/* About Us Section */}
        <section className="about-us-section py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img src={slide2} alt="About Us" className="img-fluid rounded shadow-lg" />
              </div>
              <div className="col-lg-6">
                <h2 className="section-title mb-4">About Us</h2>
                <h3 className="mb-3">Our Mission</h3>
                <p className="mb-4">Daytocode&apos;s mission is to build self-confidence in youth and women in underserved communities through education in computer programming and technology, fostering economic inclusion and digital literacy.</p>
                <h3 className="mb-3">Our Vision</h3>
                <p className="mb-4">We envision a world where every individual, regardless of background, has the opportunity to thrive in the digital age, contributing to their community&apos;s development.</p>
                <h3 className="mb-3">Our Values</h3>
                <p className="mb-4">Integrity, Consistency, Compassion</p>
                <Link to="/about-us" className="btn btn-primary btn-lg">Learn More About Us</Link>
              </div>
            </div>
          </div>
        </section>

        {/* Key Statistics Section */}
        <section className="key-statistics-section py-5" style={{
          backgroundImage: `url(${bg10})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
          <div className="container">
            <h2 className="section-title text-center mb-5">Key Statistics</h2>
            <div className="row text-center">
              <div className="col-md-4 mb-4">
                <div className="stat-card bg-white p-4 rounded shadow">
                  <h3 className="stat-number">Over 500</h3>
                  <p className="stat-description">Youth engaged since 2016 across three continents</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="stat-card bg-white p-4 rounded shadow">
                  <h3 className="stat-number">More than 100</h3>
                  <p className="stat-description">Coding and robotics workshops conducted</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="stat-card bg-white p-4 rounded shadow">
                  <h3 className="stat-number">Global Reach</h3>
                  <p className="stat-description">Programs in Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Featured Programs Section */}
        <section className="featured-programs-section py-5">
          <div className="container">
            <h2 className="section-title text-center mb-5">Featured Programs</h2>
            <div className="row">
              <div className="col-lg-4 mb-4">
                <div className="card h-100 shadow-sm">
                  <div className="card-body">
                    <h4 className="card-title">Robotics Workshops</h4>
                    <p className="card-text">Regular sessions in Montgomery County, MD</p>
                    <a href="/" className="btn btn-outline-primary mt-3">Learn More</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card h-100 shadow-sm">
                  <div className="card-body">
                    <h4 className="card-title">Summer Coding Camps</h4>
                    <p className="card-text">Annual coding camps in Wilmington, NC</p>
                    <a href="/" className="btn btn-outline-primary mt-3">Learn More</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card h-100 shadow-sm">
                  <div className="card-body">
                    <h4 className="card-title">International Outreach</h4>
                    <p className="card-text">Technology education in Guinea and Côte d&apos;Ivoire, focusing on girls&apos; empowerment</p>
                    <a href="/" className="btn btn-outline-primary mt-3">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Partnerships Section */}
        <section className="partnerships-section py-5 bg-light">
          <div className="container">
            <h2 className="section-title text-center mb-5">Our Partners</h2>
            <PartnershipSlider />
          </div>
        </section>

        <div className="footer-feature-wrapper">
          <div className="container">
            <FooterTop />
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        className="modal fade modal-wrapper"
        id="read"
        centered
        show={readModal}
        onHide={() => setReadModal(false)}
      >
        <div className="modal-body">
          <p>
            Daytocode&apos;s mission is to build self-confidence in youth and women in underserved communities through education in computer programming and technology, fostering economic inclusion and digital literacy. Since 2016, we have engaged over 500 youth across three continents, conducting more than 100 coding and robotics workshops. Our programs in Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire have made a significant impact, with over 200 students in Guinea alone benefiting in the last year.
          </p>
          <p>
            Our key programs include:
          </p>
          <ul>
            <li>Robotics Workshops: Regular sessions in Montgomery County, MD, targeting middle and high school students.</li>
            <li>Summer Coding Camps: Annual camps in Wilmington, NC, focusing on hardware exploration, Scratch coding, and robotics programming.</li>
            <li>International Outreach: Technology education initiatives in Guinea and Côte d&apos;Ivoire, with a special focus on girls&apos; empowerment and basic computer skills training.</li>
          </ul>
          <p>
            Through these programs, we aim to foster digital literacy, critical thinking, and problem-solving skills, equipping participants for future opportunities in the tech-driven world.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Home;
