import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";

// Images
import pic1 from '../../assets/images/project/wilmington_nc/murray_robotics_1.webp';
import pic2 from '../../assets/images/project/wilmington_nc/murray_robotics_2.webp';
import pic3 from '../../assets/images/project/wilmington_nc/murray_robotics_3.webp';
import pic4 from '../../assets/images/project/wilmington_nc/nh_hardware_2.webp';
import pic5 from '../../assets/images/project/wilmington_nc/williston_intro_robotic_1.webp';
// import pic6 from '../../assets/images/project/wilmington_nc/williston_intro_robotic_2.webp';
// import pic7 from '../../assets/images/project/wilmington_nc/williston_intro_robotic_3.webp';
// import pic8 from '../../assets/images/project/wilmington_nc/williston_intro_robotic_4.webp';
// import pic9 from '../../assets/images/project/wilmington_nc/williston_intro_robotic_5.webp';
// import pic10 from '../../assets/images/project/wilmington_nc/wilmington_tot_dtc_1.webp';
// import pic11 from '../../assets/images/project/wilmington_nc/wilmington_tot_dtc_2.webp';
// import pic12 from '../../assets/images/project/wilmington_nc/wilmington_tot_dtc_3.webp';

import avat1 from '../../assets/images/avatar/avatar1.webp';
import avat2 from '../../assets/images/avatar/avatar2.webp';
import avat3 from '../../assets/images/avatar/avatar3.webp';
import avat4 from '../../assets/images/avatar/avatar4.webp';
import avat5 from '../../assets/images/avatar/avatar5.webp';
import { CardData } from '../../interfaces/Interfaces';



const ProgramMasonry: React.FC = () => {
    const cardData: CardData[] = useMemo(() => [
        { cardid: '1', image: pic1, image2: avat1, progres: "75%", title: "ROBOTICS", subtitle: "Robotics Workshops in Montgomery, MD", authar: "Daytocode Team", participants: "3,542", duration: "Ongoing" },
        { cardid: '2', image: pic2, image2: avat2, progres: "85%", title: "CODING", subtitle: "Summer Coding Camps in Wilmington, NC", authar: "Daytocode Team", participants: "35,542", duration: "Annual" },
        { cardid: '3', image: pic3, image2: avat3, progres: "70%", title: "INTERNATIONAL", subtitle: "Technology Education in Guinea", authar: "Daytocode Team", participants: "2,542", duration: "Ongoing" },
        { cardid: '4', image: pic4, image2: avat4, progres: "60%", title: "INTERNATIONAL", subtitle: "Coding Camp in Abidjan, Côte d'Ivoire", authar: "Daytocode Team", participants: "6,542", duration: "July 2024" },
        { cardid: '5', image: pic5, image2: avat5, progres: "80%", title: "CONFERENCE", subtitle: "Arise 2K24 Conference in Abidjan", authar: "Daytocode Team", participants: "1,542", duration: "2024" },
    ], []);

    const [dropbtn, setDropbtn] = useState('Newest');
    const [filtered, setFiltered] = useState<CardData[]>(cardData);
    const [activeGenre, setActiveGenre] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        let result = cardData;

        // Filter by genre
        if (activeGenre !== 0) {
            result = result.filter((card) => card.title === getGenreTitle(activeGenre));
        }

        // Filter by search term
        if (searchTerm) {
            result = result.filter((card) => 
                card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                card.subtitle.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Sort by dropdown selection
        result.sort((a, b) => {
            const compareResult = b.cardid.localeCompare(a.cardid, undefined, {numeric: true, sensitivity: 'base'});
            return dropbtn === 'Oldest' ? -compareResult : compareResult;
        });

        setFiltered(result);
    }, [activeGenre, searchTerm, dropbtn, cardData]);

    const getGenreTitle = (genreId: number): string => {
        switch (genreId) {
            case 1: return "ROBOTICS";
            case 2: return "CODING";
            case 3: return "INTERNATIONAL";
            case 4: return "CONFERENCE";
            default: return "";
        }
    };

    return (
        <>
            <div className="row m-b30">
                <div className="col-xl-8 col-lg-7">
                    <div className="site-filters style-1 clearfix">
                        <ul className="filters" data-bs-toggle="buttons">
                            <li className={`btn ${activeGenre === 0 ? "active" : ""}`}>
                                <Link to={"#"} onClick={() => setActiveGenre(0)}>All Programs</Link>
                            </li>
                            <li className={`btn ${activeGenre === 1 ? "active" : ""}`}>
                                <Link to={"#"} onClick={() => setActiveGenre(1)}>Robotics</Link>
                            </li>
                            <li className={`btn ${activeGenre === 2 ? "active" : ""}`}>
                                <Link to={"#"} onClick={() => setActiveGenre(2)}>Coding</Link>
                            </li>
                            <li className={`btn ${activeGenre === 3 ? "active" : ""}`}>
                                <Link to={"#"} onClick={() => setActiveGenre(3)}>International</Link>
                            </li>
                            <li className={`btn ${activeGenre === 4 ? "active" : ""}`}>
                                <Link to={"#"} onClick={() => setActiveGenre(4)}>Conference</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2 text-start text-lg-end m-b20">
                    <input 
                        type="text" 
                        placeholder="Search programs..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="col-xl-2 col-lg-3 text-start text-lg-end m-b20">
                    <Dropdown className="select-drop">
                        <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                            <span>{dropbtn}</span>
                            <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setDropbtn('Newest')}>Newest</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDropbtn('Oldest')}>Oldest</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="clearfix">
                <ul id="masonry" className="row">
                    <AnimatePresence>
                        {filtered.map((item, index) => (
                            <motion.li
                                layout
                                initial={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className="card-container col-xl-4 col-lg-6 col-md-6 col-sm-12 m-b30"
                                key={index}
                            >
                                <div className="dz-card style-2 overlay-skew">
                                    <div className="dz-media">
                                        <Link to={"/program-detail"}><img src={item.image} alt="" /></Link>
                                    </div>
                                    <div className="dz-info">
                                        <ul className="dz-category">
                                            <li><Link to={"#"}>{item.title}</Link></li>
                                        </ul>
                                        <h5 className="dz-title"><Link to={"/program-detail"}>{item.subtitle}</Link></h5>
                                        <div className="progress-bx style-1">
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated" style={{ width: item.progres }}></div>
                                            </div>
                                            <ul className="progress-tag">
                                                <li className="raised">
                                                    <i className="las la-users"></i> Participants: <span className="text-primary">{item.participants}</span>
                                                </li>
                                                <li className="goal">
                                                    <i className="lar la-calendar"></i> Duration: <span className="text-primary">{item.duration}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="author-wrappper">
                                            <div className="author-media">
                                                <img src={item.image2} alt="" /> 
                                            </div>
                                            <div className="author-content">
                                                <div className="author-head">
                                                    <h6 className="author-name">{item.authar}</h6>
                                                </div>
                                                <ul className="author-meta">
                                                    <li className="campaign">Organizer</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.li>
                        ))}
                    </AnimatePresence>
                </ul>
            </div>
        </>
    );
};

export default ProgramMasonry;