import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Icon from '../constant/fontIcons';
// import { faCoffee, faHeart } from '@fortawesome/free-solid-svg-icons';
// import { faFacebook, faGithub, faGoogle, faMicrosoft, faTwitter } from '@fortawesome/free-brands-svg-icons'; // Brand icon

import { MenuListArray } from "./Menu";
import Logo from "../components/Home/Logo";
import { HeaderProps, MenuClass, State } from "../interfaces/Interfaces";
import DonateButton from "../components/Modal/DonateModal";

const Header: React.FC<HeaderProps> = ({ changeStyle }) => {
  const [headerFix, setheaderFix] = useState(false);

  useEffect(() => {
    const handleScroll = () => setheaderFix(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // No dependencies, should not cause an infinite loop

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const mainMenu = document.getElementById("OpenMenu");
    if (mainMenu) {
      mainMenu.classList.toggle("show", sidebarOpen);
    }
  }, [sidebarOpen]);

  const reducer = (
    previousState: State,
    updatedState: Partial<State>
  ): State => ({
    ...previousState,
    ...updatedState,
  });

  const initialState: State = {
    active: "",
    activeSubmenu: "",
  };

  const [state, setState] = useReducer(reducer, initialState);

  const handleMenuActive = (status: string) => {
    setState({ active: state.active === status ? "" : status });
  };

  const handleSubmenuActive = (status: string) => {
    setState({ activeSubmenu: state.activeSubmenu === status ? "" : status });
  };

  const [sideOverlay, setSideOverlay] = useState(false);
  const [loginModal, setloginModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);

  return (
    <>
      <header className={`site-header mo-left header style-2 ${changeStyle}`}>
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg ${
            headerFix ? "is-fixed" : ""
          }`}
        >
          <div className="main-bar clearfix ">
            <div className="container-fluid clearfix">
              <div className="logo-header mostion logo-dark">
                <Link to={"/"}>
                  <Logo />
                </Link>
              </div>
              <button
                type="button"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className={`navbar-toggler navicon justify-content-end ${
                  sidebarOpen ? "open" : "collapsed"
                }`}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="donate-button-wrapper">
                <DonateButton
                  actionUrl="https://www.paypal.com/cgi-bin/webscr"
                  hostedButtonId="FL3Q7GBUM6ELA"
                  cmd="_s-xclick"
                  buttonText="Donate Now"
                />
              </div>
              <div
                className={`header-nav navbar-collapse collapse justify-content-start ${
                  sidebarOpen ? "show" : ""
                }`}
              >
                <div className="logo-header logo-dark">
                  <Link to={"/"}>
                    <Logo />
                  </Link>
                </div>
                <ul className="nav navbar-nav navbar navbar-left">
                  {MenuListArray.map((data, index) => {
                    const menuClass = data.classChange as MenuClass;
                    if (menuClass !== "sub-menu-down") {
                      return (
                        <li className={menuClass} key={index}>
                          <Link to={data.to ?? "#"}>{data.title}</Link>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          className={`${menuClass} ${
                            state.active === data.title ? "open" : ""
                          }`}
                          key={index}
                        >
                          {data.content && data.content.length > 0 ? (
                            <Link
                              to={"#"}
                              onClick={() => handleMenuActive(data.title)}
                            >
                              {data.title}
                            </Link>
                          ) : (
                            <Link to={data.to ?? "#"}>{data.title}</Link>
                          )}
                          <Collapse in={state.active === data.title}>
                            <ul className="sub-menu">
                              {data.content?.map((subItem, subIndex) => (
                                <li
                                  key={subIndex}
                                  className={`${
                                    state.activeSubmenu === subItem.title
                                      ? "open"
                                      : ""
                                  }`}
                                >
                                  {subItem.content &&
                                  subItem.content.length > 0 ? (
                                    <>
                                      <Link
                                        to={subItem.to ?? "#"}
                                        onClick={() =>
                                          handleSubmenuActive(subItem.title)
                                        }
                                      >
                                        {subItem.title}
                                        <i className="fa fa-angle-right" />
                                      </Link>
                                      <Collapse
                                        in={
                                          state.activeSubmenu === subItem.title
                                        }
                                      >
                                        <ul className="sub-menu">
                                          {subItem.content?.map(
                                            (subSubItem, subSubIndex) => (
                                              <li key={subSubIndex}>
                                                <Link to={subSubItem.to ?? "#"}>
                                                  {subSubItem.title}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </Collapse>
                                    </>
                                  ) : (
                                    <Link to={subItem.to ?? "#"}>
                                      {subItem.title}
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </Collapse>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={`contact-sidebar ${sideOverlay ? "active" : ""}`}>
        {/* Content omitted for brevity */}
      </div>
      <div
        className="menu-close"
        role="button"
        tabIndex={0} // Set tabIndex as a number
        onClick={() => setSideOverlay(!sideOverlay)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setSideOverlay(!sideOverlay);
          }
        }}
      ></div>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        id="modalLogin"
        show={loginModal}
        onHide={() => setloginModal(false)} // Wrap setter in an arrow function
        centered
      >
        {/* Content omitted for brevity */}
      </Modal>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={resetModal}
        onHide={() => setResetModal(false)} // Wrap setter in an arrow function
        centered
      >
        {/* Content omitted for brevity */}
      </Modal>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={signupModal}
        onHide={() => setSignupModal(false)} // Wrap setter in an arrow function
        centered
      >
      </Modal>
    </>
  );
};

export default Header;
