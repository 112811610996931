import React, { useState } from "react";
import { Swiper as SwiperClass, SwiperSlide } from "swiper/react";
import { Swiper } from "swiper"; // Import the Swiper type
import "swiper/css"; // Base Swiper styles
import "swiper/css/navigation"; // Navigation styles
import "swiper/css/thumbs"; // Thumbs styles
import "swiper/css/free-mode"; // FreeMode styles

// Import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

// Updated image imports
import img1 from "../../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_1.webp";
import img2 from "../../assets/images/project/wilmington_nc/williston_intro_robotic_3.webp";
import img3 from "../../assets/images/project/wilmington_nc/williston_intro_robotic_4.webp";
import img4 from "../../assets/images/project/wilmington_nc/williston_intro_robotic_5.webp";
import img5 from "../../assets/images/project/wilmington_nc/williston_intro_robotic_4.webp";
import img6 from "../../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_3.webp";

const swiperImages = [
  { image: img1, alt: "Robotics workshop in Maryland" },
  { image: img2, alt: "Coding camp in Wilmington, NC" },
  { image: img3, alt: "International outreach in Conakry, Guinea" },
  { image: img4, alt: "Coding camp in Abidjan, Côte d'Ivoire" },
  { image: img5, alt: "Arise 2K24 Conference in Abidjan" },
  { image: img6, alt: "Summer Coding Camp in Wilmington, NC" },
];

export default function GallerySlider() {
  // Type the state as Swiper or null
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);

  return (
    <>
      <SwiperClass
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-swiper"
      >
        {swiperImages.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="dz-media">
              <img src={item.image} alt={item.alt} />
            </div>
          </SwiperSlide>
        ))}
      </SwiperClass>
      <SwiperClass
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-thumb"
      >
        {swiperImages.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="dz-media">
              <img src={item.image} alt={item.alt} />
            </div>
          </SwiperSlide>
        ))}
      </SwiperClass>
    </>
  );
}
