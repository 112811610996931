import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/pagination"; // Import pagination styles

// Import images (you&apos;ll need to replace these with actual images)
import pic1 from "./../../assets/images/testimonials/pic4.webp";
import pic2 from "./../../assets/images/testimonials/pic4.webp";
import pic3 from "./../../assets/images/testimonials/pic4.webp";
import pic4 from "./../../assets/images/testimonials/pic4.webp";
import pic5 from "./../../assets/images/testimonials/pic4.webp";

const testimonials = [
  {
    image: pic1,
    name: "Olney Student",
    position: "Summer Coding Camp Participant, Wilmington, NC",
    text: "The biggest thing I learned was doing coding, learning how to do different stuff on a computer, and drawing on different apps. I think it&apos;s important because you can learn all kinds of things to make new apps when you&apos;re older. Coding has opened up so many possibilities for me, and I&apos;m excited about what I can create next.",
  },
  {
    image: pic2,
    name: "Salima Toudoumoua",
    position: "10th Grade Student, Koumandjan High School, Guinea",
    text: "Since I started using the computer, I&apos;ve learned so much. Now, I can use the mouse, manipulate files, and even create documents—things I never knew how to do before. This knowledge has opened up new possibilities for me, and I feel empowered to do more.",
  },
  {
    image: pic3,
    name: "François Feuillet",
    position: "10th Grade Student, Koumandjan High School, Guinea",
    text: "Before joining this program, I didn&apos;t know much about computers. Now, I can identify the screen, mouse, and keyboard, and even create my own files. This experience has been eye-opening, and I&apos;m thankful for the opportunity to learn.",
  },
  {
    image: pic4,
    name: "Abidjan Participant",
    position: "Coding Camp at Harvest Church Angré, Côte d&apos;Ivoire",
    text: "This was my first time coding, and I was amazed at how quickly I could learn to program a robot. The instructors were patient and helped us every step of the way. Now, I feel like I can take on more complex projects and maybe even pursue a career in technology.",
  },
  {
    image: pic5,
    name: "Doug McLean",
    position: "Strategic Advisor, DAI, Wilmington, NC",
    text: "Our goal when we started this was to build some confidence in the kids so that they can be daring and bold in taking things apart, building, and becoming creators instead of just consumers. The creativity they&apos;ve shown through this project is remarkable, and it&apos;s exciting to see them sharing their knowledge and what they&apos;ve developed. We look forward to supporting future editions of this camp and making an even greater impact.",
  },
];

const TestimonialSlider = () => {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Swiper
        className="testimonial-swiper"
        speed={1500}
        slidesPerView={"auto"}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        // Set up navigation elements before initialization
        onBeforeInit={(swiper) => {
          if (
            typeof swiper.params.navigation !== 'boolean' &&
            swiper.params.navigation &&
            navigationPrevRef.current &&
            navigationNextRef.current
          ) {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }
        }}
        // Activate navigation
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{ clickable: true }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial-1">
              <div className="testimonial-text">
                <p>{"\u201C"}{testimonial.text}{"\u201D"}</p>
              </div>
              <div className="testimonial-details">
                <div className="testimonial-info">
                  <div className="testimonial-pic">
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                  <div className="clearfix">
                    <h5 className="testimonial-name">{testimonial.name}</h5>
                    <span className="testimonial-position">{testimonial.position}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Navigation buttons */}
        <div className="swiper-btn container swiper-btn-center-lr">
          <div
            className="test-swiper-prev btn-prev style-1"
            ref={navigationPrevRef}
          >
            <i className="fa fa-arrow-left-long"></i>
          </div>
          <div
            className="test-swiper-next btn-next style-1"
            ref={navigationNextRef}
          >
            <i className="fa fa-arrow-right-long"></i>
          </div>
        </div>
      </Swiper>
    </>
  );
};

export default TestimonialSlider;
