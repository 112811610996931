import React from "react";
import { Link } from "react-router-dom";

import Pagebanner from "../layouts/PageBanner";

//images
import bg from "../assets/images/background/dtc_williston_intro_robotic.webp";
import shape2 from "../assets/images/pattern/shape2.webp";
import shape3 from "../assets/images/pattern/shape3.webp";
import shape5 from "../assets/images/pattern/shape5.webp";
import shape1 from "../assets/images/pattern/shape1.webp";
import shape6 from "../assets/images/pattern/shape6.webp";
import large1 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_9.webp";
import large2 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_12.webp";
import large3 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_13.webp";


import OurMission from "../components/OurMission";
import UpdateBlog from "../components/Home/UpdateBlog";

const Mission: React.FC = () => {
  function isScrolledIntoView(elem: string) {
    const spliBox = document.querySelectorAll<HTMLElement>(elem);
    spliBox.forEach((item: HTMLElement) => {
      console.log("splitbox", item);
      const docViewTop = window.scrollY;
      const docViewBottom = docViewTop + window.innerHeight;
      const elemTop = item.getBoundingClientRect().top + docViewTop;
      const elemBottom = elemTop + item.offsetHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        item.classList.add("split-active");
      }
    });
  }

  window.addEventListener("scroll", () => {
    isScrolledIntoView(".split-box");
  });

  return (
    <>
      <div className="page-content bg-white">
        <Pagebanner pagetitle="Our Mission and Impact" maintitle="Home" background={bg} />
        <section className="content-inner-2 bg-light section-pattren1">
          <div className="container">
            <div className="row about-bx3 align-items-center">
              <OurMission />
            </div>
          </div>
          <img src={shape2} className="shape-6 move-2" alt="shape" />
          <img src={shape3} className="shape-5 move-1" alt="shape" />
          <img src={shape5} className="shape-1 rotating" alt="shape" />
          <img src={shape1} className="shape-3 move-1" alt="shape" />
          <img src={shape6} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>
        <section className="content-inner">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30">
              <div className="col-lg-6 col-md-12">
                <div className="dz-media split-box">
                  <div>
                    <img src={large1} alt="" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="dz-content">
                  <h2 className="title m-b15">Our Mission</h2>
                  <p className="m-b0">
                    Daytocode empowers youth and women in underserved communities through technology education, fostering confidence, inclusion, and digital literacy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center project-bx right">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-content">
                  <h2 className="title m-b15">Our Vision</h2>
                  <p className="m-b0">
                    We envision a world where every individual, regardless of background, has the opportunity to thrive in the digital age, contributing to their community&apos;s development.
                  </p>
                  <Link to={"/about-us"} className="btn btn-primary m-t30">
                    Learn More About Us <i className="fa-solid fa-angle-right ms-2"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media split-box">
                  <div>
                    <img src={large2} alt="" className="app-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>

        <section className="content-inner-2">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30">
              <div className="col-lg-6 col-md-12">
                <div className="dz-media split-box">
                  <div>
                    <img src={large3} alt="" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="dz-content">
                  <h2 className="title m-b15">Our Impact</h2>
                  <ul className="list-check primary m-b30">
                    <li>Over 500 youth engaged since 2016 across three continents</li>
                    <li>More than 100 coding and robotics workshops conducted</li>
                    <li>Programs in Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire</li>
                    <li>Over 200 students impacted in Guinea alone in the last year</li>
                  </ul>
                  <div className="signature m-t30">
                   <p className="text-dark m-t20 mb-0">Emmanuel Bamba</p>
                    <p className="text-dark m-t20 mb-0">Founder, Daytocode</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mission;
