import React from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

//Images
import pic4 from "./../../assets/images/project/wilmington_nc/williston_intro_robotic_1.webp";
import pic5 from "./../../assets/images/project/wilmington_nc/murray_robotics_1.webp";
import pic6 from "./../../assets/images/project//wilmington_nc/nh_hardware_2.webp";
import avt1 from "./../../assets/images/avatar/avatar1.webp";
import avt2 from "./../../assets/images/avatar/avatar2.webp";
import avt3 from "./../../assets/images/avatar/avatar3.webp";

// import Swiper core and required modules
import { Autoplay } from "swiper/modules";

const dataBlog = [
  { 
    image: pic4, 
    image2: avt1,
    category: "ROBOTICS",
    title: "Robotics Workshops in Montgomery County, MD",
    raised: 3542,
    daysLeft: 43,
    authorName: "Daytocode Team",
    campaigns: 12,
    location: "Silver Spring, Olney"
  },
  { 
    image: pic5, 
    image2: avt2,
    category: "CODING",
    title: "Summer Coding Camps in Wilmington, NC",
    raised: 5000,
    daysLeft: 30,
    authorName: "DAI Partnership",
    campaigns: 8,
    location: "New Hanover County"
  },
  { 
    image: pic6, 
    image2: avt3,
    category: "INTERNATIONAL",
    title: "Technology Education in Guinea and Côte d'Ivoire",
    raised: 2800,
    daysLeft: 60,
    authorName: "Global Outreach",
    campaigns: 15,
    location: "Conakry, Abidjan"
  },
];

const RecentProjectsSlider = () => {
  return (
    <>
      <Swiper
        className="recent-blog p-b5"
        speed={1500}
        //parallax= {true}
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {dataBlog.map((d, i) => (
          <SwiperSlide key={i}>
            <div
              className="dz-card style-2 overlay-skew wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="dz-media">
                <Link to={"/program-detail"}>
                  <img src={d.image} alt={d.title} />
                </Link>
              </div>
              <div className="dz-info">
                <ul className="dz-category">
                  <li>
                    <Link to={"#"}>{d.category}</Link>
                  </li>
                </ul>
                <h5 className="dz-title">
                  <Link to={"/program-detail"}>{d.title}</Link>
                </h5>
                <div className="progress-bx style-1">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow={75} // Changed from "75" to 75
                      aria-valuemin={0} // Changed from "0" to 0
                      aria-valuemax={100} // Changed from "100" to 100
                      style={{ width: "75%" }}
                    ></div>
                  </div>
                  <ul className="progress-tag">
                    <li className="raised">
                      <i className="las la-coins"></i>{" "}
                      <span>Total Raised $ {d.raised}</span>
                    </li>
                    <li className="goal">
                      <i className="lar la-calendar"></i>{" "}
                      <span>{d.daysLeft} Days left</span>
                    </li>
                  </ul>
                </div>
                <div className="author-wrappper">
                  <div className="author-media">
                    <img src={d.image2} alt={d.authorName} />
                  </div>
                  <div className="author-content">
                    <div className="author-head">
                      <h6 className="author-name">{d.authorName}</h6>
                      {/* ... existing rating ... */}
                    </div>
                    <ul className="author-meta">
                      <li className="campaign">{d.campaigns} Campaign</li>
                      <li className="location">{d.location}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default RecentProjectsSlider;
