import React from 'react';
import { Link } from 'react-router-dom';

import baner from '../assets/images/main-slider/slide_dtc/dtc_robot_workshop_4_9_28_0.webp';
import LogoOrange from "../components/Home/LogoOrange";
import shape4 from '../assets/images/side-images/shape4.webp';
import line4 from '../assets/images/side-images/shape-line4.webp';
import shape5 from '../assets/images/side-images/shape5.webp';
import line5 from '../assets/images/side-images/shape-line5.webp';

const Footer = () => {
    return (
        <>
            <footer className="site-footer style-2" id="footer">
                <div className="footer-top footer-feature background-luminosity" style={{backgroundImage: "url("+ baner +")"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-12 " >
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-white">
                                        <Link to={"/"}> <LogoOrange /></Link> 
                                    </div>
                                    <p>Empowering the Next Generation through Technology Education. Daytocode&apos;s mission is to build self-confidence in youth and women in underserved communities through education in computer programming and technology, fostering economic inclusion and digital literacy.</p>
                                    <div className="dz-social-icon style-3">
                                        <ul>
                                            <li><a target="_blank" className="fab fa-facebook-f"  rel="noreferrer" href="https://www.facebook.com/daytocode">&nbsp;</a></li>
                                            <li><a target="_blank" className="fab fa-instagram"  rel="noreferrer" href="https://www.instagram.com/daytocode">&nbsp;</a></li>
                                            <li><a target="_blank" className="fab fa-twitter"  rel="noreferrer" href="https://twitter.com/daytocode">&nbsp;</a></li>
                                            <li><a target="_blank" className="fab fa-youtube"  rel="noreferrer" href="https://youtube.com/@daytocode">&nbsp;</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 " >
                                <div className="widget widget_services">
                                    <h4 className="widget-title">Our Programs</h4>
                                    <div className="separator-2 bg-secondary m-b30"></div>
                                    <ul>
                                        <li><Link to={"/workshops"}>Robotics Workshops</Link></li>
                                        <li><Link to={"/workshops"}>Summer Coding Camps</Link></li>
                                        <li><Link to={"/workshops"}>International Outreach</Link></li>
                                        <li><Link to={"/story"}>Success Stories</Link></li>
                                        <li><Link to={"/about-us"}>About Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 " >
                                <div className="widget recent-posts-entry">
                                    <h4 className="widget-title">Latest News</h4>
                                    <div className="separator-2 bg-secondary m-b30"></div>
                                    <div className="widget-post-bx">
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><Link to={"/blog-details"}>Coding Camp Success in Abidjan</Link></h6>
                                                <span className="post-date">July, 2024</span>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><Link to={"/blog-details"}>Arise 2K24 Conference Highlights</Link></h6>
                                                <span className="post-date">2024</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-12 " >
                                <div className="widget widget_locations">
                                    <h4 className="widget-title">Our Reach</h4>
                                    <div className="separator-2 bg-secondary m-b30"></div>
                                    <div className="clearfix">
                                        <h6 className="title">USA and Africa</h6>
                                        <p>Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="shape1" src={shape4} alt=""/>
                    <img className="shape2" src={line4 } alt=""/>
                    <img className="shape3" src={shape5} alt=""/>
                    <img className="shape4" src={line5 } alt=""/>
                </div>
                
                <div className="footer-bottom text-center">
                    <div className="container">
                        <span className="copyright-text">Daytocode - © 2024 by <a href="https://daytocode.org/"  rel="noreferrer" target="_blank">Daytocode</a> | A 501(c)(3) Nonprofit Organization</span>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;