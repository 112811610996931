import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

import slide from './../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_20.webp';

const CounterBlog = [
    { title: "Total Donor", number: 100 },
    { title: "Volunteer", number: 20 },
    { title: "Total Donation", number: 55000 },
];

const OurMission = () => {
    return (
        <>
            <div className="col-lg-5">
                <div className="dz-media">
                    <img src={slide} alt="Description of the slide" />
                </div>
            </div>
            <div className="col-lg-7 m-b50">
                <div className="section-head">
                    <h5 className="sub-title">Our Mission</h5>
                    <h2>Building self-confidence & digital literacy</h2>
                    <p className="m-t20">
                    Daytocode empowers youth and women in underserved communities through technology education, fostering confidence, inclusion, and digital literacy.
                    </p>
                </div>
                <div className="row">
                    {CounterBlog.map((item, index) => (
                        <div className="col-lg-3 col-sm-4 col-6 m-b30" key={index}>
                            <h2>
                                <span className="counter">
                                    {item.title.toLowerCase().includes('donation') && '$'}
                                    <CountUp
                                        end={item.number} 
                                        separator=","
                                        duration={3}  // Pass duration as a number
                                    />
                                </span>
                            </h2>
                            <span className="counter-text">{item.title}</span>
                        </div>
                    ))}
                </div>
                <Link to={"/story"} className="m-r30 m-sm-r10 btn btn-secondary">See Projects <i className="flaticon-right-arrows ps-3"></i></Link>
                <Link to={"/how-it-works"} className="btn btn-outline-dark">How It Works</Link>
            </div>
        </>
    );
}

export default OurMission;
