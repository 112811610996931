import React, { useState } from 'react';
import { Stepper, Step } from 'react-form-stepper';

// components
import PageBanner from '../layouts/PageBanner';
import FirstStep from '../components/Fundraiser/FirstStep';
import SecondStep from '../components/Fundraiser/SecondStep';
import ThirdStep from '../components/Fundraiser/ThirdStep';
import FourthStep from '../components/Fundraiser/FourthStep';

// images
import bg from '../assets/images/background/dtc_robotic_bg.webp';
import bg2 from '../assets/images/background/dtc_williston_intro_robotic.webp';

interface StepProps {
  onNext: () => void;
  onPrevious?: () => void;
}

const StepNavigation: React.FC<StepProps> = ({ onNext, onPrevious }) => (
  <div className="text-end toolbar toolbar-bottom p-2">
    {onPrevious && (
      <button className="btn sw-btn-prev sw-btn me-1" onClick={onPrevious}>
        Previous
      </button>
    )}
    <button className="btn sw-btn-next sw-btn ms-1" onClick={onNext}>
      {onPrevious ? 'Next' : 'Submit'}
    </button>
  </div>
);

const BecomeFundraiser: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    { component: FirstStep, hasNext: true },
    { component: SecondStep, hasNext: true, hasPrevious: true },
    { component: ThirdStep, hasNext: true, hasPrevious: true },
    { component: FourthStep, hasNext: false, hasPrevious: true },
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // Handle form submission
      console.log('Form submitted');
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner 
          maintitle="Join Our Mission" 
          pagetitle="Become A Fundraiser" 
          background={bg} 
        />
        <section
          className="content-inner-1 section-pattren1 overlay-white-dark"
          style={{ backgroundImage: `url("${bg2}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="text-center mb-5">
                  <h2>Help Us Bridge Digital Divides</h2>
                  <p>Join Daytocode in empowering youth and women through technology education. Your support can make a difference in communities across Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire.</p>
                </div>
                <div className="form-wrapper overflow-hidden">
                  <div className="form-wizard dz-form-step">
                    <Stepper className="nav nav-wizard" activeStep={currentStep}>
                      {steps.map((_, index) => (
                        <Step
                          key={index}
                          className="nav-link"
                          onClick={() => setCurrentStep(index)}
                        />
                      ))}
                    </Stepper>
                    <CurrentStepComponent />
                    <StepNavigation
                      onNext={handleNext}
                      onPrevious={steps[currentStep].hasPrevious ? handlePrevious : undefined}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BecomeFundraiser;
