import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";
import PartnershipSlider from "../components/Home/PartnershipSlider";
import UpdateBlog from "../components/Home/UpdateBlog";

import bg from "../assets/images/background/dtc_robotic_bg.webp";
import shape1 from "../assets/images/pattern/shape1.webp";
import shape3 from "../assets/images/pattern/shape3.webp";
import shape5 from "../assets/images/pattern/shape5.webp";
import shape6 from "../assets/images/pattern/shape6.webp";
import shape7 from "../assets/images/pattern/shape7.webp";

const cardBlog = [
  {
    title: "Trusted Partner",
    subtitle: "394-091-3312",
    icon: "flaticon-phone-call-1",
  },
  { title: "Mail", subtitle: "support@daytocode.org", icon: "flaticon-email" },
  {
    title: "Our Address",
    subtitle: "832 Thompson Drive, San Francisco, United States",
    icon: "flaticon-pin",
  },
];

const ContactUs: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          "service_gfykn6i",
          "template_iy1pb0b",
          form.current,
          "HccoOtZS6GHw-N-m6"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      form.current.reset();
      swal("Good job!", "Form successfully submitted", "success");
    }
  };

  return (
    <div className="page-content bg-white">
      <PageBanner maintitle="Contact" pagetitle="Contact Us" background={bg} />
      <section className="content-inner-1 bg-light section-pattren1">
        <div className="container">
          <div className="row justify-content-center">
            {cardBlog.map((item, ind) => (
              <div className="col-lg-4 col-md-6 m-b20" key={ind}>
                <div className="icon-bx-wraper box-hover style-3">
                  <div className="icon-lg">
                    <Link to={"/services-details"} className="icon-cell">
                      <i className={item.icon}></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dz-tilte m-b5 text-capitalize">
                      {item.title}
                    </h5>
                    <span>{item.subtitle}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img src={shape1} className="shape-1 move-1" alt="shape" />
        <img src={shape3} className="shape-3 move-1" alt="shape" />
        <img src={shape5} className="shape-4 rotating" alt="shape" />
        <img src={shape6} className="shape-5 rotating" alt="shape" />
      </section>
      <section className="content-inner map-wrapper1">
        <div className="container-fluid">
          <div className="map-iframe style-1">
  
          </div>
          <div className="row justify-content-end">
            <div className="col-xl-6 col-lg-8 col-sm-12">
              <div className="contact-info form-wrapper style-1">
                <h2 className="title">Write us a message</h2>
                <div className="contact-area">
                  <form
                    className="dz-form dzForm contact-bx"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="dzFormMsg"></div>
                    <input
                      type="hidden"
                      className="form-control"
                      name="dzToDo"
                      value="Contact"
                    />
                    <div className="row sp15">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="dzFirstName">
                          First Name
                        </label>
                        <div className="input-group">
                          <input
                            id="dzFirstName"
                            name="dzFirstName"
                            required
                            type="text"
                            className="form-control"
                            placeholder="John"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="dzLastName">
                          Last Name
                        </label>
                        <div className="input-group">
                          <input
                            id="dzLastName"
                            name="dzLastName"
                            required
                            type="text"
                            className="form-control"
                            placeholder="Deo"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="dzEmail">
                          Email address
                        </label>
                        <div className="input-group">
                          <input
                            id="dzEmail"
                            name="dzEmail"
                            required
                            type="email"
                            className="form-control"
                            placeholder="info@example.com"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="dzPhoneNumber">
                          Phone Number
                        </label>
                        <div className="input-group">
                          <input
                            id="dzPhoneNumber"
                            name="dzPhoneNumber"
                            required
                            type="text"
                            className="form-control"
                            placeholder="987 654 3210"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="dzMessage">
                          Message
                        </label>
                        <div className="input-group">
                          <textarea
                            id="dzMessage"
                            name="dzMessage"
                            rows={7} // Changed from string to number
                            required
                            className="form-control"
                            placeholder="Dear Sir/Madam"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn btn-secondary"
                        >
                          Submit Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <img src={shape5} className="shape-2 move-2" alt="shape" />
          <img src={shape7} className="shape-1 move-2" alt="shape" />
          <img src={shape6} className="shape-3 move-2" alt="shape" />
        </div>
      </section>
      <section className="clients-wrapper p-0">
        <div className="container">
          <div className="section-head text-center">
            <h3 className="title">Our Partnership</h3>
          </div>
          <PartnershipSlider />
        </div>
      </section>
      <div className="call-action style-1 content-inner-1">
        <div className="container">
          <UpdateBlog />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
