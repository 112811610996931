import React from "react";

interface DonateButtonProps {
  actionUrl: string; // The base URL to redirect to
  hostedButtonId: string; // The PayPal hosted button ID
  cmd: string; // The PayPal command
  buttonText: string; // Text to display on the button
}

const DonateButton: React.FC<DonateButtonProps> = ({
  actionUrl = "https://www.paypal.com/cgi-bin/webscr",
  hostedButtonId = "FL3Q7GBUM6ELA",
  cmd = "_s-xclick",
  buttonText = "Donate Now"
}) => {
  const handleClick = () => {
    // Redirect to the PayPal URL with preselected amount
    window.location.href = `${actionUrl}?cmd=${cmd}&hosted_button_id=${hostedButtonId}`;
  };

  return (
    <div className="extra-nav">
      <div className="extra-cell d-flex align-items-center">
        <button
          type="button"
          className="btn btn-primary btnhover1"
          onClick={handleClick}
          aria-label={buttonText}
        >
          <span>{buttonText}</span>
          <i className="flaticon-heart text-secondary ms-3"></i>
        </button>
      </div>
    </div>
  );
};

export default DonateButton;
