import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import large from '../assets/images/project/wilmington_nc/williston_intro_robotic_1.webp';
import blog2 from '../assets/images/project/wilmington_nc/williston_intro_robotic_2.webp';
import blog1 from '../assets/images/project/wilmington_nc/williston_intro_robotic_3.webp';

import { CommentBlogProps } from '../interfaces/Interfaces';

export const CommentBlog: React.FC<CommentBlogProps> = (props) => {
  return (
    <>
      <div className="comment-body">
        <div className="comment-author vcard">
          <img className="avatar photo" src={props.image} alt="" />
          <cite className="fn">{props.title}</cite>
        </div>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <div className="reply">
          <Link to={"#"} className="comment-reply-link"><i className="fa fa-reply"></i>Reply</Link>
        </div>
      </div>
    </>
  );
};

// PropTypes validation
CommentBlog.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// Default props (optional)
CommentBlog.defaultProps = {
  image: '',
  title: 'Default Title',
};

const BlogDetailsLeftBar: React.FC = () => {
  return (
    <>
      <div className="dz-card blog-single sidebar">
        <div className="dz-media">
          <img src={large} alt="Robotics workshop in Maryland" />
        </div>
        <div className="dz-info">
          <div className="dz-meta">
            <ul>
              <li className="post-date"><i className="fa-solid fa-calendar-days"></i> 15 July, 2024</li>
              <li className="post-author"><Link to={"#"}><i className="fa-solid fa-user"></i> By Daytocode Team</Link></li>
            </ul>
          </div>
          <div className="dz-post-text">
            <h1 className="dz-title">Empowering Youth Through Technology: Daytocode&apos;s Journey</h1>
            <p>Since 2016, Daytocode has been on a mission to empower the next generation through technology education. Our journey has taken us from Montgomery County, MD to international shores, impacting lives and bridging digital divides along the way.</p>
            <ul className="wp-block-gallery columns-3">
              <li className="blocks-gallery-item"><img alt="Coding camp in Wilmington, NC" src={blog2} /></li>
              <li className="blocks-gallery-item"><img alt="International outreach in Guinea" src={blog1} /></li>
            </ul>
            <p>From robotics workshops in Maryland libraries to summer coding camps in Wilmington, NC, and international outreach programs in Guinea and Côte d&apos;Ivoire, we&apos;ve engaged over 500 youth across three continents.</p>
            <blockquote className="block-quote style-1">
              <p>&quot;Coding has opened up so many possibilities for me, and I&apos;m excited about what I can create next.&quot;</p>
              <cite>Summer Coding Camp Participant, Wilmington, NC</cite>
            </blockquote>
            <p>Our programs are designed to foster digital literacy, critical thinking, and problem-solving skills. We&apos;ve seen remarkable growth in our participants, particularly in building self-confidence among girls through basic computer skills training and coding workshops.</p>
            <ul>
              <li>Robotics Workshops in Montgomery County, MD</li>
              <li>Summer Coding Camps in Wilmington, NC</li>
              <li>International Outreach in Guinea and Côte d&apos;Ivoire</li>
              <li>Arise 2K24 Conference in Abidjan, Côte d&apos;Ivoire</li>
              <li>Partnerships with DAI and Harvest Intercontinental Ministries</li>
            </ul>
            <p>As we continue to grow, we remain committed to our vision of a world where every individual, regardless of background, has the opportunity to thrive in the digital age. Join us in our mission to empower the next generation through technology education.</p>
          </div>
        </div>
      </div>  
    </>
  );
};

export default BlogDetailsLeftBar;