import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

const programCategories = [
    { title: "Robotics Workshops" }, { title: "Coding Camps" },
    { title: "International Outreach" }, { title: "Girls in ICT" },
    { title: "Hardware Exploration" }, { title: "Web Programming" },
    { title: "Cloud Computing" }, { title: "STEM Education" },
];

const FirstStep = () => {
    const [dropbtn, setDropbtn] = useState('United States');
    return (
        <div id="wizard_Time" className="tab-pane" role="tabpanel">
            <h4 className="title">Where are you located?</h4>
            <div className="row sp15">
                <div className="col-md-6">
                    <label className="form-label" htmlFor="countryDropdown">Country</label>
                    <Dropdown className="select-drop">
                        <Dropdown.Toggle as="div" className="i-false select-drop-btn" id="countryDropdown">
                            <span>{dropbtn}</span>
                            <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setDropbtn('United States')}>United States</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDropbtn('Guinea')}>Guinea</Dropdown.Item>
                            <Dropdown.Item onClick={() => setDropbtn('Côte d\'Ivoire')}>Côte d&apos;Ivoire</Dropdown.Item>
                            {/* Add other relevant countries */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="col-md-6">
                    <label className="form-label" htmlFor="dzPostcode">Postcode</label>
                    <div className="input-group">
                        <input id="dzPostcode" name="dzPostcode" required type="text" className="form-control" placeholder="324009" />
                    </div>
                </div>
            </div>
            <h4 className="title mt-4">Which programs interest you?</h4>
            <div className="tag-donate style-1">
                {programCategories.map((data, index) => (
                    <div className="donate-categories" key={index}>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id={`flexCheckDefault${index + 1}`} />
                            <label className="form-check-label" htmlFor={`flexCheckDefault${index + 1}`}>
                                <span>{data.title}</span>
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FirstStep;
