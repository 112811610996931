import React from 'react';

const ThirdStep = () => {
    return (
        <div id="wizard_contact" className="tab-pane" role="tabpanel">
            <h4 className="title">Donation Information</h4>
            <div className="row sp15">
                <div className="col-md-12">
                    <div className="form-group mb-3 row style-1 align-items-center">
                        <label htmlFor="amount" className="form-label">Donation Amount (USD)</label>
                        <div className="input-group">
                            <input type="number" id="amount" className="form-control" placeholder="Enter amount" />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label htmlFor="program" className="form-label">Allocate to Program (Optional)</label>
                    <select id="program" name="program" className="form-select">
                        <option value="">General Fund</option>
                        <option value="robotics">Robotics Workshops</option>
                        <option value="coding">Summer Coding Camps</option>
                        <option value="international">International Outreach</option>
                    </select>
                </div>
            </div>
            <p className="mt-3">Your donation helps bridge the digital divide and empowers underserved communities through technology education.</p>
        </div>
    );
};

export default ThirdStep;