export const MenuListArray = [
    {
        title:"Home",
         to : "/"
       
    },
    {
        title:"Programs",
        classChange:"sub-menu-down",
        content : [
            {
                title:"Workshops",
                to:"/workshops"
            },
            {
                title:"Past Workshops",
                to:"/past-workshops"
            },
            {
                title:"Story",
                to:"/story"
            },
        ],
    },
    {
        title:"About Us",
        classChange:"sub-menu-down",
        content : [
            {
                title : "About Us",
                to : "/about-us"
            },
            {
                title : "Mission",
                to : "/mission"
            },
            {
                title : "Volunteer",
                to : "#",
                className:"sub-menu",
                content : [
                    {
                        title:"Volunteer",
                        to:"/volunteer"
                    },
                    {
                        title:"Become A Volunteer",
                        to:"/become-a-volunteer"
                    },
                ],
            },
            {
                title : "Faq",
                to : "/faq"
            },
            {
                title : "Testimonials",
                to : "/testimonials"
            }
        ],
    },
    
    {
        title:"Fundraising",
        classChange:"sub-menu-down",
        content : [
            {
                title:"Fundraising",
                to:"/fundraising"
            },
            {
                title:"Become A Fundraiser",
                to:"/become-a-fundraiser"
            }
        ],
    },
    {
        title:"Blog",
        classChange:"sub-menu-down",
        content:[
            {
                title:"Blog",
                to:"/blog"
            },
            {
                title:"Blog Grid",
                to:"/blog-grid"
            },
            {
                title:"Blog Details",
                to:"/blog-details"
            },
        ],
    },
    {   
        title: 'Contact Us',	
        to: './contact-us',
    },

];