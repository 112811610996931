import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";

//images
import bg from "../assets/images/banner/bnr4.webp";

import placeholderImage from "../assets/images/main-slider/slide_dtc/dtc_robot_workshop_2_1.webp";

const cardDetails = [
  {
    image: placeholderImage,
    tag: "Event",
    title: "Arise 2K24 Conference in Abidjan, Côte d'Ivoire",
    excerpt: "Daytocode conducted pop-up workshops on Web Programming, Robotics, and Cloud Computing at the Arise 2K24 Conference...",
    author: "Daytocode Team",
    date: "July 15, 2024",
  },
  {
    image: placeholderImage,
    tag: "Program",
    title: "Coding Camp at Harvest Church Angré, Abidjan",
    excerpt: "In partnership with Harvest Intercontinental Ministries Unlimited, Daytocode held a successful coding camp introducing participants to basic coding concepts and robotics...",
    author: "Program Coordinator",
    date: "July 10, 2024",
  },
  {
    image: placeholderImage,
    tag: "Outreach",
    title: "Expanding Digital Literacy in Guinea",
    excerpt: "Daytocode's initiatives in Guinea have equipped over 190 students with essential digital skills, significantly contributing to gender equality in technology education...",
    author: "Outreach Team",
    date: "June 5, 2024",
  },
  // Add more blog posts as needed
];

const BlogGrid = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Blog" pagetitle="Latest News and Updates" background={bg} />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              {cardDetails.map((item, ind) => (
                <div className="col-xl-4 col-md-6 m-b30" key={ind}>
                  <div className="dz-card style-1">
                    <div className="dz-media">
                      <Link to={`/blog-details/${ind}`}>
                        <img src={item.image} alt={item.title} />
                      </Link>
                      <ul className="dz-badge-list">
                        <li>
                          <Link to={"#"} className="dz-badge">
                            {item.tag}
                          </Link>
                        </li>
                      </ul>
                      <Link to={`/blog-details/${ind}`} className="btn btn-secondary">
                        Read More
                      </Link>
                    </div>
                    <div className="dz-info">
                      <h5 className="dz-title">
                        <Link to={`/blog-details/${ind}`}>{item.title}</Link>
                      </h5>
                      <p>{item.excerpt}</p>
                      <div className="author-wrappper">
                        <div className="author-content">
                          <div className="author-head">
                            <h6 className="author-name">{item.author}</h6>
                          </div>
                          <ul className="author-meta">
                            <li className="date">{item.date}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-12 m-sm-t0 m-t30">
                <nav className="pagination-bx">
                  <div className="page-item">
                    <Link to={"#"} className="page-link prev">
                      <i className="fas fa-chevron-left"></i>
                    </Link>
                  </div>
                  <ul className="pagination">
                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        1
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link active">
                        2
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        3
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link to={"#"} className="page-link">
                        4
                      </Link>
                    </li>
                  </ul>
                  <div className="page-item">
                    <Link to={"#"} className="page-link next">
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="call-action style-1 content-inner-1">
          <div className="container">{/* <UpdateBlog /> */}</div>
        </div>
      </div>
    </>
  );
};

export default BlogGrid;
