import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Base Swiper styles
import "swiper/css/navigation"; // Navigation module styles
import "swiper/css/thumbs"; // Thumbs module styles
import "swiper/css/free-mode"; // FreeMode module styles
import { Autoplay, Navigation, EffectFade, Pagination } from "swiper/modules";

import shape1 from "../../assets/images/main-slider/slider1/shape1.svg";
import line1 from "../../assets/images/main-slider/slider1/shape-line1.svg";
import shape2 from "../../assets/images/main-slider/slider1/shape2.svg";
import line2 from "../../assets/images/main-slider/slider1/shape-line2.svg";
import dtc_workshop1 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_9_1.webp";
import dtc_workshop2 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_9_2.webp";
import dtc_workshop3 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_9_4.webp";
import dtc_workshop4 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_2_1.webp";
import dtc_workshop5 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_2_2.webp";
import dtc_workshop6 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_2_3.webp";
import dtc_workshop7 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_4_9_28_0.webp";
import dtc_workshop8 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_4_9_28_0.webp";
import dtc_workshop9 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_4_9_28_1.webp";
import dtc_workshop10 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_4_9_28_2.webp";
import dtc_workshop11 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_4_9_28_3.webp";
import dtc_workshop12 from "../../assets/images/main-slider/slide_dtc/dtc_robot_workshop_11_23_1.webp";


const dataBlog = [
  {
    title1: "EMPOWERING THE NEXT GENERATION",
    title2: "Through Technology Education",
    baner: dtc_workshop1,
    img2: dtc_workshop2,
    img3: dtc_workshop3,
    img4: dtc_workshop4,
  },
  {
    title1: "BRIDGING DIGITAL DIVIDES",
    title2: "Locally and Globally Since 2016",
    baner: dtc_workshop5,
    img2: dtc_workshop6,
    img3: dtc_workshop7,
    img4: dtc_workshop8,
  },
  {
    title1: "FOSTERING DIGITAL LITERACY",
    title2: "In Underserved Communities",
    baner: dtc_workshop9,
    img2: dtc_workshop10,
    img3: dtc_workshop11,
    img4: dtc_workshop12,
  },
];

import { Swiper as SwiperCore } from 'swiper';

const Mainslider = () => {
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperCore | null>(null);

  return (
    <>
      <Swiper
        className="main-slider"
        speed={1500}
        slidesPerView={1}
        effect="fade"
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay, Navigation, EffectFade, Pagination]} // Added Pagination module
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{ clickable: true }} // Enabled pagination
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          swiper.navigation.update();
        }}
      >
        {dataBlog.map((d, i) => (
          <SwiperSlide key={i}>
            <div
              className="banner-inner"
              style={{ backgroundImage: `url(${d.baner})` }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="banner-content">
                      <div className="top-content">
                        <h5 className="sub-title text-secondary">{d.title1}</h5>
                        <h1 className="title">{d.title2}</h1>
                        <p>
                          Daytocode&apos;s mission is to build self-confidence in youth and women in underserved communities through education in computer programming and technology, fostering economic inclusion and digital literacy.
                        </p>
                        <div className="d-flex align-items-center">
                          <Link
                            to={"/workshops"}
                            className="btn btn-primary btnhover1"
                          >
                            <span>Join Our Programs</span>
                            <i className="flaticon-right-arrows ms-3"></i>
                          </Link>
                          <Link
                            to={"/mission"}
                            className="btn btn-secondary btnhover2"
                          >
                            Support Our Mission{" "}
                            <i className="flaticon-heart text-secondary ms-3"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="banner-media row gx-4">
                      <div className="col-5">
                        <div className="main-img1">
                          <img src={d.img2} alt="" />
                        </div>
                        <div className="main-img2">
                          <img src={d.img3} alt="" />
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="main-img3">
                          <img src={d.img4} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="shape1" src={shape1} alt="" />
              <img className="shape2" src={line1} alt="" />
              <img className="shape3" src={shape2} alt="" />
              <img className="shape4" src={line2} alt="" />
            </div>
          </SwiperSlide>
        ))}
        <div className="bottom-wrapper">
          <ul className="social-list">
            <li>
              <a href="https://www.facebook.com/daytocode" rel="noreferrer">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://twitter.com/daytocode" rel="noreferrer">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/daytocode" rel="noreferrer">
                Instagram
              </a>
            </li>
          </ul>
          <div className="btn-wrapper">
            <div 
              className="main-btn main-btn-prev" 
              ref={navigationPrevRef}
              onClick={() => swiperRef.current?.slidePrev()}
              onKeyDown={(e) => e.key === 'Enter' && swiperRef.current?.slidePrev()}
              role="button"
              tabIndex={0}
              aria-label="Previous slide"
            >
              <i className="fa fa-angle-left"></i>
            </div>
            <div 
              className="main-btn main-btn-next" 
              ref={navigationNextRef}
              onClick={() => swiperRef.current?.slideNext()}
              onKeyDown={(e) => e.key === 'Enter' && swiperRef.current?.slideNext()}
              role="button"
              tabIndex={0}
              aria-label="Next slide"
            >
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
      </Swiper>
    </>
  );
};

export default Mainslider;
